import * as THREE from 'three'
import gsap from 'gsap'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'

console.log(OrbitControls);
/**
 * Cursor 
 * */ 
const cursor = {
    x:0,
    y:0
}
window.addEventListener('mousemove',(event) =>{
    cursor.x = event.clientX / sizes.width - 0.5;
    cursor.y = -(event.clientY / sizes.height - 0.5);
    // console.log(cursor);
})

 
/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Base
 */
const geometry = new THREE.BoxGeometry(1, 1, 1,5,5,5)
const material = new THREE.MeshBasicMaterial({ color: 0xff0000 })
const mesh = new THREE.Mesh(geometry, material)
scene.add(mesh)

/**
 * Sizes
 */
const sizes = {
    width: 800,
    height: 600
}

/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(85, sizes.width / sizes.height,1,10000)
camera.position.z= 2
camera.lookAt(mesh.position);
scene.add(camera);
const controls = new OrbitControls(camera,canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.render(scene, camera);


// const clock = new THREE.Clock();


/* gsap.to(mesh.position,{duration:1,delay:1,x:2});
gsap.to(mesh.position,{duration:1,delay:2,x:0}); */

// Animations
const tick = () => {
    
    // mesh.rotation.reorder('YXZ');
    // mesh.rotation.y = clock.getElapsedTime();
    // mesh.rotation.x += 0.02;
    // console.log(cursor.x);
    
    // 随着鼠标移动
/*     camera.position.x=Math.sin(cursor.x * Math.PI * 2) * 2;
    camera.position.z=Math.cos(cursor.x * Math.PI * 2) * 2;
    camera.position.y=cursor.y * 3;
    camera.lookAt(new THREE.Vector3()); */


    controls.update();
    renderer.render(scene, camera);
    window.requestAnimationFrame(tick);
}

tick();

/**
 * Animate
 */
/* gsap.to(mesh.position, { duration: 1, delay: 1, x: 2 })

const tick = () =>
{
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick() */